/**
 * This is an example of how you can customize the theme of this web app.  To use:
 * 1) Copy this file into a `custom.scss` file.
 * 2) Uncomment the import statement in app.scss.
 * 3) Run gulp to compile it.
 */
$main-color:                #E32512;
$secondary-color:           #FFFF00;
$brand-primary:             $main-color !default;
$btn-success-color:         $secondary-color !default;
$brand-success:             #ffffff !default;
$btn-success-color:         $main-color !default;
$brand-danger:              $secondary-color !default;
$font-size-base:            18px !default;
$panel-default-heading-bg:  #ffffff !default;
nav.navbar {
  a {
    color: $secondary-color !important;
  }

  .navbar-brand {
    font-size: 19px !important;
  }
}
@media (min-width:320px) and (max-width:599px) {
  nav.navbar .navbar-brand {
    font-size: 14px !important;
  }
}
.btn-success {
    color: $main-color !important;
    background-color: $secondary-color !important;
    border-color: $secondary-color !important;
}
.btn-success:hover {
    color: #ffffff !important;
    background-color: $main-color !important;
    border-color: $main-color !important;
}
.navbar-inverse .navbar-nav > li > a:hover,
.navbar-inverse .navbar-nav > li > a:focus,
.navbar-inverse .navbar-nav > .active > a,
.navbar-inverse .navbar-nav > .active > a:hover,
.navbar-inverse .navbar-nav > .active > a:focus,
.navbar-inverse .dropdown-menu > li > a:hover,
.navbar-inverse .dropdown-menu > .active > a,
.navbar-inverse .dropdown-menu > .active > a:hover {
  background-color: $secondary-color !important;
  color: $main-color !important;
}
.navbar-inverse .navbar-toggle .icon-bar {
  background-color: $secondary-color !important;
}
.navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
  border: 1px solid $secondary-color !important;
  // background-color: $secondary-color !important;
}
.navbar .navbar-toggle:hover .icon-bar {
  background-color: $secondary-color !important;
}
.panel-default .panel-heading a {
    color: $main-color;
}
.jssocials-share-email .jssocials-share-link {
  background-color: $main-color !important;
}
#prayer-details {
    margin-top: 20px;
    margin-bottom: 20px;
}
.learn-more-page-header {
    display: none;
}
.picker__year, .picker__weekday {
    color: $main-color;
}
.picker__day--today:before {
    border-top: 0.5em solid $secondary-color;
}
.picker__month,
.picker__day--outfocus {
    color: $secondary-color;
}
.picker__day--selected:hover,
.picker__day--highlighted:hover,
.picker__day--infocus:hover,
.picker__day--outfocus:hover,
.picker__button--today:hover,
.picker__button--clear:hover,
.picker__button--close:hover,
.picker__nav--prev:hover,
.picker__nav--next:hover {
    color: #ffffff;
    background: $main-color;
}
.picker__day--highlighted {
    border: none;
    border-color: none;
}
.picker--focused .picker__day--highlighted,
.picker__day--selected,
.picker--focused .picker__day--selected {
    color: #ffffff;
    background: $secondary-color;
}
.picker__button--today:before {
    border-top: 0.66em solid $secondary-color;
}
.picker__nav--prev:before,
.picker__nav--next:before {
    border-top: .5em solid transparent;
    border-bottom: .5em solid transparent;
    border-right: 0.75em solid $main-color;
}
.picker__nav--next:before {
    border-right: 0;
    border-left: 0.75em solid $main-color;
}
.picker__nav--prev:hover:before {
    border-top: .5em solid transparent;
    border-bottom: .5em solid transparent;
    border-right: 0.75em solid #ffffff;
}
.picker__nav--next:hover:before {
    border-top: .5em solid transparent;
    border-bottom: .5em solid transparent;
    border-right: 0;
    border-left: 0.75em solid #ffffff;
}
.owl-carousel {
  max-height: 650px;
  .item > img {
    width: auto;
    max-width: 100%;
    max-height: 650px;
    margin: 0px auto;
  }
}
.footer-wrapper .bible-reference {
  text-align: center;
  font-size: 14px;
  padding: 40px 0 0;
}
.italicized {
    font-style: italic;
}
.underlined {
    text-decoration: underline;
}
.bio-content {
    margin: 40px 0px;
}
.bios-page {
    font-size: 14px;

    h2 {
        font-size: 3rem;
    }

    h4 {
        font-size: 2.5rem;
    }

    h5 {
        font-size: 2rem;
    }
}
.dropdown-menu {
	min-width: 200px;
}
.dropdown-menu.columns-2 {
	min-width: 400px;
}
.dropdown-menu.columns-3 {
	min-width: 600px;
}
.dropdown-menu li a {
	padding: 5px 15px;
	font-weight: 300;
}
.multi-column-dropdown {
	list-style: none;
  margin: 0px;
  padding: 0px;
}
.multi-column-dropdown li a {
	display: block;
	clear: both;
	line-height: 1.428571429;
	color: $secondary-color !important;
	white-space: normal;
}
.multi-column-dropdown li a:hover {
	text-decoration: none;
	color: $main-color !important;
	background-color: $secondary-color !important;
}
.scrollable-menu {
    height: auto;
    max-height: 400px;
    overflow-x: hidden;
}
@media (min-width: 996px) and (max-width: 1090px) {
	.dropdown-menu.columns-3 {
		min-width: 500px !important;
		overflow-x: hidden;
	}
}
@media (min-width: 951px) and (max-width: 995px) {
	.dropdown-menu.columns-3 {
		min-width: 400px !important;
		overflow-x: hidden;
	}
}
// @media (min-width: 768px) and (max-width: 920px) {
// 	.dropdown-menu.columns-3 {
// 		min-width: 320px !important;
// 		overflow-x: hidden;
// 	}
// }
@media (max-width: 950px) {
	.dropdown-menu.multi-column {
		min-width: 240px !important;
		overflow-x: hidden;
	}
}

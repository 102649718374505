/**
 * Import your custom styles (Optional)
 * Uncomment to use.
 */
@import "resources/assets/sass/custom";
/**
 * resources/assets/sass/bootswatch/yeti/_variables.scss
 */
@import "resources/assets/sass/bootswatch/yeti/variables";
/**
 * Location of the fonts.
 * @type {String}
 */
$icon-font-path: "/fonts/bootstrap/";
$mdi-font-path: "/fonts/mdi/";
$fa-font-path: "/fonts/font-awesome";
/**
 * node_modules/bootstrap-sass/assets/stylesheets/bootstrap
 */
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
/**
 * resources/assets/sass/bootswatch/yeti/_bootswatch.scss
 */
@import "resources/assets/sass/bootswatch/yeti/bootswatch";
/**
 * node_modules/mdi/scss/materialdesignicons
 */
@import "node_modules/mdi/scss/materialdesignicons";
/**
 * node_modules/font-awesome/scss/font-awesome.scss
 */
@import "node_modules/font-awesome/scss/font-awesome.scss";
/**
 * node_modules/jssocials/styles/jssocials.scss
 */
@import "node_modules/jssocials/styles/jssocials.scss";
/**
 * node_modules/jssocials/styles/jssocials-theme-flat.scss
 */
@import "node_modules/jssocials/styles/jssocials-theme-flat.scss";
/**
 * app.scss
 */
body {
    padding-top: 50px;
}
.praying-element{
    a {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    i {
        font-size: 28px;
        position: relative;
        top: 5px;
    }
}
.wrapper-inverse {
    background-color: $navbar-inverse-bg;
    color: $navbar-inverse-color;
}
.form-group.has-error label {
    color: $brand-danger;
}
.footer-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
}
.get-app-image {
    margin: 20px 0px;
    width: 200px;
}
.rtl {
    direction: rtl;
    text-align: right;
}
/*!
 * Styling for RTL (right-to-left) languages using pickadate.js
 */
/**
 * Switch the direction - only really necessary if
 * it hasn’t already been applied higher up in the DOM.
 */
.picker.rtl {
  direction: rtl;
}
/**
 * Flip around the “next” and “previous” buttons.
 */
.picker__nav--next.rtl {
  right: auto;
  left: -1em;
}
.picker__nav--prev.rtl {
  left: auto;
  right: -1em;
}
.picker__nav--next.rtl:before {
  border-left: 0;
  border-right: 0.75em solid #000000;
}
.picker__nav--prev.rtl:before {
  border-right: 0;
  border-left: 0.75em solid #000000;
}
#contact-response {
    margin: 20px 0px;
}
#prayer-share {
    text-align: center;
    margin: 30px 0px;
}
/* I want to collapse early. */
@media (max-width: 950px) {
	.dropdown-menu.multi-column {
		min-width: 240px !important;
		overflow-x: hidden;
	}
    .navbar-header {
        float: none !important;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block !important;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
    }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
    }
    .navbar-nav>li {
        float: none !important;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
}
